export enum AuAdverseEventField {
    brandName = "BRAND_NAME",
    dateReceived = "DATE_RECEIVED",
    eventType = "EVENT_TYPE",
    gmdn = "GMDN",
    manufacturer = "MANUFACTURER",
    productCode = "PRODUCT_CODE",
    reportedEventOutcome = "REPORTED_EVENT_OUTCOME"
}
export enum AuMaudeFieldName {
    brandName = "BRAND_NAME",
    eventType = "EVENT_TYPE",
    manufacturer = "MANUFACTURER",
    receivedDate = "RECEIVED_DATE",
    relevance = "RELEVANCE",
    reportNumber = "REPORT_NUMBER"
}
export enum AuRecallField {
    basilDateCreated = "BASIL_DATE_CREATED",
    brandName = "BRAND_NAME",
    establishmentName = "ESTABLISHMENT_NAME",
    familyBrand = "FAMILY_BRAND",
    familyCompany = "FAMILY_COMPANY",
    productCode = "PRODUCT_CODE",
    recallingFirm = "RECALLING_FIRM",
    recallAction = "RECALL_ACTION",
    recallClass = "RECALL_CLASS",
    recallDate = "RECALL_DATE",
    recallNumber = "RECALL_NUMBER",
    recallReason = "RECALL_REASON"
}
export enum BrandField {
    familyCompany = "FAMILY_COMPANY",
    establishmentName = "ESTABLISHMENT_NAME"
}
export enum BrandWithIn {
    brandName = "BRAND_NAME"
}
export enum CaAdverseEventField {
    brandName = "BRAND_NAME",
    dateReceived = "DATE_RECEIVED",
    manufacturer = "MANUFACTURER",
    patientDevice = "PATIENT_DEVICE"
}
export enum CaMaudeFieldName {
    brandName = "BRAND_NAME",
    eventType = "EVENT_TYPE",
    manufacturer = "MANUFACTURER",
    receivedDate = "RECEIVED_DATE",
    relevance = "RELEVANCE",
    reportNumber = "REPORT_NUMBER"
}
export enum CaRecallField {
    basilDateCreated = "BASIL_DATE_CREATED",
    brandName = "BRAND_NAME",
    establishmentName = "ESTABLISHMENT_NAME",
    familyBrand = "FAMILY_BRAND",
    familyCompany = "FAMILY_COMPANY",
    productCode = "PRODUCT_CODE",
    recallingFirm = "RECALLING_FIRM",
    recallClass = "RECALL_CLASS",
    recallDate = "RECALL_DATE",
    recallNumber = "RECALL_NUMBER",
    recallReason = "RECALL_REASON"
}
export enum ClinicalTrialField {
    collaboratorFilter = "COLLABORATOR_FILTER",
    condition = "CONDITION",
    conditionFilter = "CONDITION_FILTER",
    drugBrand = "DRUG_BRAND",
    ingredientActiveCode = "INGREDIENT_ACTIVE_CODE",
    ingredientInactiveCode = "INGREDIENT_INACTIVE_CODE",
    ingredientActiveName = "INGREDIENT_ACTIVE_NAME",
    ingredientInactiveName = "INGREDIENT_INACTIVE_NAME",
    interventionType = "INTERVENTION_TYPE",
    lastUpdateDate = "LAST_UPDATE_DATE",
    meddraHighLevelGroupTerm = "MEDDRA_HIGH_LEVEL_GROUP_TERM",
    meddraHighLevelTerm = "MEDDRA_HIGH_LEVEL_TERM",
    meddraPreferredTerm = "MEDDRA_PREFERRED_TERM",
    meddraSystemOrganClass = "MEDDRA_SYSTEM_ORGAN_CLASS",
    nctId = "NCT_ID",
    participantCount = "PARTICIPANT_COUNT",
    phase = "PHASE",
    relatedAppType = "RELATED_APP_TYPE",
    resultsPosted = "RESULTS_POSTED",
    section = "SECTION",
    sponsorCollaborator = "SPONSOR_COLLABORATOR",
    sponsorFilter = "SPONSOR_FILTER",
    startDate = "START_DATE",
    status = "STATUS",
    studyType = "STUDY_TYPE"
}
export enum ClinicalTrialFieldName {
    lastUpdateDate = "LAST_UPDATE_DATE",
    participantCount = "PARTICIPANT_COUNT",
    postedDate = "POSTED_DATE",
    startDate = "START_DATE"
}
export enum ClinicalTrialSource {
    clinicaltrialsGov = "clinicaltrials.gov"
}
export enum CombinedDrugField {
    adverseReactions = "ADVERSE_REACTIONS",
    clinicalPharmacology = "CLINICAL_PHARMACOLOGY",
    contraindications = "CONTRAINDICATIONS",
    descriptionSection = "DESCRIPTION_SECTION",
    dosageAndAdministration = "DOSAGE_AND_ADMINISTRATION",
    dosageFormsAndStrengths = "DOSAGE_FORMS_AND_STRENGTHS",
    drugInteractions = "DRUG_INTERACTIONS",
    emaId = "EMA_ID",
    emcId = "EMC_ID",
    howSuppliedStorageAndHandling = "HOW_SUPPLIED_STORAGE_AND_HANDLING",
    indicationsAndUsage = "INDICATIONS_AND_USAGE",
    label = "LABEL",
    mechanismOfAction = "MECHANISM_OF_ACTION",
    nonclinicalToxicology = "NONCLINICAL_TOXICOLOGY",
    overdosageSection = "OVERDOSAGE_SECTION",
    splId = "SPL_ID",
    useInSpecificPopulations = "USE_IN_SPECIFIC_POPULATIONS",
    warningsAndPrecautions = "WARNINGS_AND_PRECAUTIONS"
}
export enum CompanyWithIn {
    brandName = "BRAND_NAME"
}
export enum CountWithIn {
    brandName = "BRAND_NAME"
}
export enum DeNovoField {
    applicant = "APPLICANT",
    brandName = "BRAND_NAME",
    committee = "COMMITTEE",
    decisionDate = "DECISION_DATE",
    establishmentName = "ESTABLISHMENT_NAME",
    familyCompany = "FAMILY_COMPANY",
    productCode = "PRODUCT_CODE",
    recallCount = "RECALL_COUNT",
    reviewPanel = "REVIEW_PANEL"
}
export enum DeNovoTableFieldName {
    applicant = "APPLICANT",
    brandName = "BRAND_NAME",
    decisionDate = "DECISION_DATE",
    number = "NUMBER",
    reviewPanel = "REVIEW_PANEL",
    reviewTime = "REVIEW_TIME",
    productCode = "PRODUCT_CODE",
    title = "TITLE"
}
export enum DeNovoType {
    direct = "Direct",
    postNse = "Post-NSE",
    "510KTraditional" = "510(k) Traditional"
}
export enum DeviceField {
    applicant = "APPLICANT",
    applicationNumber = "APPLICATION_NUMBER",
    brandName = "BRAND_NAME",
    clinicalTrial = "CLINICAL_TRIAL",
    collaboratorFilter = "COLLABORATOR_FILTER",
    committee = "COMMITTEE",
    condition = "CONDITION",
    conditionFilter = "CONDITION_FILTER",
    decisionDate = "DECISION_DATE",
    establishmentName = "ESTABLISHMENT_NAME",
    familyCompany = "FAMILY_COMPANY",
    interventionType = "INTERVENTION_TYPE",
    lastUpdateDate = "LAST_UPDATE_DATE",
    participantCount = "PARTICIPANT_COUNT",
    phase = "PHASE",
    postApprovalStudy = "POST_APPROVAL_STUDY",
    productCode = "PRODUCT_CODE",
    recallCount = "RECALL_COUNT",
    relatedAppType = "RELATED_APP_TYPE",
    resultsPosted = "RESULTS_POSTED",
    sponsorCollaborator = "SPONSOR_COLLABORATOR",
    sponsorFilter = "SPONSOR_FILTER",
    startDate = "START_DATE",
    status = "STATUS",
    studyType = "STUDY_TYPE",
    submissionType = "SUBMISSION_TYPE"
}
export enum DeviceFieldName {
    applicant = "APPLICANT",
    decisionDate = "DECISION_DATE",
    lastUpdateDate = "LAST_UPDATE_DATE",
    participantCount = "PARTICIPANT_COUNT",
    recallCount = "RECALL_COUNT",
    relevance = "RELEVANCE",
    startDate = "START_DATE",
    title = "TITLE"
}
export enum DeviceWithin {
    brandName = "BRAND_NAME",
    collaboratorFilter = "COLLABORATOR_FILTER",
    company = "COMPANY",
    conditions = "CONDITIONS",
    deviceName = "DEVICE_NAME",
    indicationsForUse = "INDICATIONS_FOR_USE",
    sponsorCollaborator = "SPONSOR_COLLABORATOR",
    sponsorFilter = "SPONSOR_FILTER",
    supplement = "SUPPLEMENT"
}
export enum DrugField {
    activeCodeIngredient = "ACTIVE_CODE_INGREDIENT",
    activeIngredient = "ACTIVE_INGREDIENT",
    administrationRoute = "ADMINISTRATION_ROUTE",
    adverseReactions = "ADVERSE_REACTIONS",
    boxedWarning = "BOXED_WARNING",
    brandName = "BRAND_NAME",
    clinicalPharmacology = "CLINICAL_PHARMACOLOGY",
    clinicalStudiesSection = "CLINICAL_STUDIES_SECTION",
    contraindications = "CONTRAINDICATIONS",
    descriptionSection = "DESCRIPTION_SECTION",
    dosageAndAdministration = "DOSAGE_AND_ADMINISTRATION",
    dosageFormsAndStrengths = "DOSAGE_FORMS_AND_STRENGTHS",
    drugAbuseAndDependence = "DRUG_ABUSE_AND_DEPENDENCE",
    drugCategory = "DRUG_CATEGORY",
    drugInteractions = "DRUG_INTERACTIONS",
    howSuppliedStorageAndHandling = "HOW_SUPPLIED_STORAGE_AND_HANDLING",
    inactiveCodeIngredient = "INACTIVE_CODE_INGREDIENT",
    inactiveIngredient = "INACTIVE_INGREDIENT",
    indicationsAndUsage = "INDICATIONS_AND_USAGE",
    ingredient = "INGREDIENT",
    label = "LABEL",
    mechanismOfAction = "MECHANISM_OF_ACTION",
    nonclinicalToxicology = "NONCLINICAL_TOXICOLOGY",
    overdosageSection = "OVERDOSAGE_SECTION",
    patientCounselingInformation = "PATIENT_COUNSELING_INFORMATION",
    precautionsSection = "PRECAUTIONS_SECTION",
    referencesSection = "REFERENCES_SECTION",
    representedOrganization = "REPRESENTED_ORGANIZATION",
    splId = "SPL_ID",
    useInSpecificPopulations = "USE_IN_SPECIFIC_POPULATIONS",
    warningsAndPrecautions = "WARNINGS_AND_PRECAUTIONS"
}
export enum DrugFieldName {
    clinicalTrial = "CLINICAL_TRIAL",
    faers = "FAERS"
}
export enum DrugsFdaField {
    brandName = "BRAND_NAME",
    company = "COMPANY",
    id = "ID",
    label = "LABEL",
    adverseReactions = "ADVERSE_REACTIONS",
    boxedWarning = "BOXED_WARNING",
    clinicalPharmacology = "CLINICAL_PHARMACOLOGY",
    clinicalStudiesSection = "CLINICAL_STUDIES_SECTION",
    contraindications = "CONTRAINDICATIONS",
    descriptionSection = "DESCRIPTION_SECTION",
    dosageAndAdministration = "DOSAGE_AND_ADMINISTRATION",
    dosageFormsAndStrengths = "DOSAGE_FORMS_AND_STRENGTHS",
    drugAbuseAndDependence = "DRUG_ABUSE_AND_DEPENDENCE",
    drugInteractions = "DRUG_INTERACTIONS",
    howSuppliedStorageAndHandling = "HOW_SUPPLIED_STORAGE_AND_HANDLING",
    indicationsAndUsage = "INDICATIONS_AND_USAGE",
    medicationGuide = "MEDICATION_GUIDE",
    nonclinicalToxicology = "NONCLINICAL_TOXICOLOGY",
    overdosageSection = "OVERDOSAGE_SECTION",
    patientCounselingInformation = "PATIENT_COUNSELING_INFORMATION",
    patientInformation = "PATIENT_INFORMATION",
    referencesSection = "REFERENCES_SECTION",
    therapeuticEquivalents = "THERAPEUTIC_EQUIVALENTS",
    useInSpecificPopulations = "USE_IN_SPECIFIC_POPULATIONS",
    warningsAndPrecautions = "WARNINGS_AND_PRECAUTIONS"
}
export enum EuDrugField {
    emaId = "EMA_ID"
}
export enum EuukDrugField {
    clinicalParticulars = "CLINICAL_PARTICULARS",
    contraindications = "CONTRAINDICATIONS",
    dosimetry = "DOSIMETRY",
    effectsOnAbilityToDriveAndUseMachines = "EFFECTS_ON_ABILITY_TO_DRIVE_AND_USE_MACHINES",
    emaId = "EMA_ID",
    emcId = "EMC_ID",
    excipientsOfKnownEffect = "EXCIPIENTS_OF_KNOWN_EFFECT",
    fertilityPregnancyLactation = "FERTILITY_PREGNANCY_LACTATION",
    generalDescription = "GENERAL_DESCRIPTION",
    incompatibilities = "INCOMPATIBILITIES",
    indicationsAndUsage = "INDICATIONS_AND_USAGE",
    label = "LABEL",
    listOfExipients = "LIST_OF_EXIPIENTS",
    marketingAuthorisationHolder = "MARKETING_AUTHORISATION_HOLDER",
    marketingAuthorisationNumbers = "MARKETING_AUTHORISATION_NUMBERS",
    mechanismOfAction = "MECHANISM_OF_ACTION",
    interactionWithOtherMedicinalProductsAndOtherFormsOfInteraction = "INTERACTION_WITH_OTHER_MEDICINAL_PRODUCTS_AND_OTHER_FORMS_OF_INTERACTION",
    nameOfTheMedicinalProduct = "NAME_OF_THE_MEDICINAL_PRODUCT",
    natureAndContentsOfContainer = "NATURE_AND_CONTENTS_OF_CONTAINER",
    overdose = "OVERDOSE",
    pharmaceuticalForm = "PHARMACEUTICAL_FORM",
    pharmaceuticalParticulars = "PHARMACEUTICAL_PARTICULARS",
    pharmacodynamicProperties = "PHARMACODYNAMIC_PROPERTIES",
    pharmacokineticProperties = "PHARMACOKINETIC_PROPERTIES",
    pharmacologicalProperties = "PHARMACOLOGICAL_PROPERTIES",
    posologyAndMethodOfAdministration = "POSOLOGY_AND_METHOD_OF_ADMINISTRATION",
    preclinicalSafetyData = "PRECLINICAL_SAFETY_DATA",
    qualitativeAndQuantitativeComposition = "QUALITATIVE_AND_QUANTITATIVE_COMPOSITION",
    qualitativeAndQuantitativeCompositionSection = "QUALITATIVE_AND_QUANTITATIVE_COMPOSITION_SECTION",
    instructionsForPreparationOfRadiopharmaceuticals = "INSTRUCTIONS_FOR_PREPARATION_OF_RADIOPHARMACEUTICALS",
    shelfLife = "SHELF_LIFE",
    specialPrecautionsForDisposal = "SPECIAL_PRECAUTIONS_FOR_DISPOSAL",
    specialPrecautionsForStorage = "SPECIAL_PRECAUTIONS_FOR_STORAGE",
    specialWarningsAndPrecautionsForUse = "SPECIAL_WARNINGS_AND_PRECAUTIONS_FOR_USE",
    therapeuticIndications = "THERAPEUTIC_INDICATIONS",
    undersirableEffects = "UNDERSIRABLE_EFFECTS"
}
export enum ExecutiveRegulatoryAlertFrequency {
    daily = "DAILY",
    weekly = "WEEKLY",
    monthly = "MONTHLY"
}
export enum ExecutiveRegulatoryDateRange {
    "1M" = "1M",
    "3M" = "3M"
}
export enum ExecutiveRegulatoryInspectionDateRange {
    "1Y" = "1Y",
    "3Y" = "3Y",
    "5Y" = "5Y"
}
export enum FaersField {
    administrationRoute = "ADMINISTRATION_ROUTE",
    ageGroup = "AGE_GROUP",
    ageYear = "AGE_YEAR",
    drugBrand = "DRUG_BRAND",
    drugCharacterization = "DRUG_CHARACTERIZATION",
    drugNdc = "DRUG_NDC",
    fulfillExpediteCriteria = "FULFILL_EXPEDITE_CRITERIA",
    ingredientActiveCode = "INGREDIENT_ACTIVE_CODE",
    ingredientActiveName = "INGREDIENT_ACTIVE_NAME",
    ingredientInactiveName = "INGREDIENT_INACTIVE_NAME",
    ingredientInactiveCode = "INGREDIENT_INACTIVE_CODE",
    meddraHighLevelGroupTerm = "MEDDRA_HIGH_LEVEL_GROUP_TERM",
    meddraHighLevelTerm = "MEDDRA_HIGH_LEVEL_TERM",
    meddraPreferredTerm = "MEDDRA_PREFERRED_TERM",
    meddraSystemOrganClass = "MEDDRA_SYSTEM_ORGAN_CLASS",
    medicinalProduct = "MEDICINAL_PRODUCT",
    occurCountry = "OCCUR_COUNTRY",
    qualification = "QUALIFICATION",
    reactionOutcome = "REACTION_OUTCOME",
    receiveDate = "RECEIVE_DATE",
    serious = "SERIOUS",
    seriousness = "SERIOUSNESS",
    seriousnessCongenitalAnomali = "SERIOUSNESS_CONGENITAL_ANOMALI",
    seriousnessDeath = "SERIOUSNESS_DEATH",
    seriousnessDisabling = "SERIOUSNESS_DISABLING",
    seriousnessHospitalization = "SERIOUSNESS_HOSPITALIZATION",
    seriousnessLifeThreatening = "SERIOUSNESS_LIFE_THREATENING",
    seriousnessOther = "SERIOUSNESS_OTHER",
    sex = "SEX"
}
export enum FaersFieldName {
    receiveDate = "RECEIVE_DATE"
}
export enum FaersHistogram {
    meddra = "MEDDRA",
    reactionOutcome = "REACTION_OUTCOME",
    safetyReportId = "SAFETY_REPORT_ID"
}
export enum FamilyField {
    companyName = "COMPANY_NAME",
    dataSource = "DATA_SOURCE",
    familyCompany = "FAMILY_COMPANY",
    familyCompanyId = "FAMILY_COMPANY_ID"
}
export enum FamilyType {
    establishment = "ESTABLISHMENT",
    other = "OTHER",
    owner = "OWNER"
}
export enum Field {
    applicationNumber = "APPLICATION_NUMBER",
    applicationType = "APPLICATION_TYPE",
    brandName = "BRAND_NAME",
    companyName = "COMPANY_NAME",
    devicePublishDate = "DEVICE_PUBLISH_DATE",
    establishmentName = "ESTABLISHMENT_NAME",
    familyBrand = "FAMILY_BRAND",
    familyCompany = "FAMILY_COMPANY",
    gmdn = "GMDN",
    productCode = "PRODUCT_CODE",
    recordKey = "RECORD_KEY",
    singleUse = "SINGLE_USE"
}
export enum FileExportType {
    csv = "csv",
    xlsx = "xlsx"
}
export enum FiveTenKField {
    applicant = "APPLICANT",
    brandName = "BRAND_NAME",
    clinicalTrial = "CLINICAL_TRIAL",
    committee = "COMMITTEE",
    decisionDate = "DECISION_DATE",
    establishmentName = "ESTABLISHMENT_NAME",
    familyCompany = "FAMILY_COMPANY",
    productCode = "PRODUCT_CODE",
    recallCount = "RECALL_COUNT",
    reviewPanel = "REVIEW_PANEL",
    submissionType = "SUBMISSION_TYPE"
}
export enum FiveTenKTableFieldName {
    applicant = "APPLICANT",
    brandName = "BRAND_NAME",
    decisionDate = "DECISION_DATE",
    number = "NUMBER",
    reviewPanel = "REVIEW_PANEL",
    reviewTime = "REVIEW_TIME",
    productCode = "PRODUCT_CODE",
    title = "TITLE"
}
export enum GudidFieldName {
    brandName = "BRAND_NAME",
    catalogNumber = "CATALOG_NUMBER",
    companyName = "COMPANY_NAME",
    distributionEndDate = "DISTRIBUTION_END_DATE",
    distributionStatus = "DISTRIBUTION_STATUS",
    deviceDescription = "DEVICE_DESCRIPTION",
    devicePublishDate = "DEVICE_PUBLISH_DATE",
    versionModelNumber = "VERSION_MODEL_NUMBER"
}
export enum GuidanceField {
    center = "CENTER",
    communicationType = "COMMUNICATION_TYPE",
    decisionDate = "DECISION_DATE",
    issueDate = "ISSUE_DATE",
    primaryIdHash = "PRIMARY_ID_HASH",
    productField = "PRODUCT_FIELD"
}
export enum GuidanceFieldName {
    issueDate = "ISSUE_DATE"
}
export enum HdeField {
    applicationNumber = "APPLICATION_NUMBER",
    applicant = "APPLICANT",
    brandName = "BRAND_NAME",
    clinicalTrial = "CLINICAL_TRIAL",
    committee = "COMMITTEE",
    decisionDate = "DECISION_DATE",
    deviceNumber = "DEVICE_NUMBER",
    establishmentName = "ESTABLISHMENT_NAME",
    familyCompany = "FAMILY_COMPANY",
    postApprovalStudy = "POST_APPROVAL_STUDY",
    productCode = "PRODUCT_CODE",
    recallCount = "RECALL_COUNT",
    reviewPanel = "REVIEW_PANEL"
}
export enum HdeSupplementField {
    applicant = "APPLICANT",
    decisionDate = "DECISION_DATE",
    applicationNumber = "APPLICATION_NUMBER",
    reviewPanel = "REVIEW_PANEL",
    supplementNumber = "SUPPLEMENT_NUMBER",
    supplementType = "SUPPLEMENT_TYPE"
}
export enum HdeSupplementTableFieldName {
    applicant = "APPLICANT",
    decisionDate = "DECISION_DATE",
    number = "NUMBER",
    reviewPanel = "REVIEW_PANEL",
    productCode = "PRODUCT_CODE",
    title = "TITLE"
}
export enum HdeTableFieldName {
    applicant = "APPLICANT",
    brandName = "BRAND_NAME",
    decisionDate = "DECISION_DATE",
    number = "NUMBER",
    reviewPanel = "REVIEW_PANEL",
    reviewTime = "REVIEW_TIME",
    productCode = "PRODUCT_CODE",
    title = "TITLE"
}
export enum Histogram {
    applicationType = "APPLICATION_TYPE",
    brandName = "BRAND_NAME",
    companyName = "COMPANY_NAME"
}
export enum HistogramPeriod {
    week = "WEEK",
    month = "MONTH"
}
export enum HistogramWithIn {
    brandName = "BRAND_NAME"
}
export enum InspectionField {
    basilDateCreated = "BASIL_DATE_CREATED",
    citationExists = "CITATION_EXISTS",
    companyName = "COMPANY_NAME",
    endDate = "END_DATE",
    establishmentName = "ESTABLISHMENT_NAME",
    familyCompany = "FAMILY_COMPANY",
    inspectionId = "INSPECTION_ID"
}
export enum InspectionTableFieldName {
    endDate = "END_DATE",
    familyCompany = "FAMILY_COMPANY",
    hasCitations = "HAS_CITATIONS",
    legalName = "LEGAL_NAME",
    productType = "PRODUCT_TYPE"
}
export enum MatrixField {
    brandName = "BRAND_NAME",
    date = "DATE",
    gmdn = "GMDN",
    manufacturer = "MANUFACTURER",
    productCode = "PRODUCT_CODE"
}
export enum MaudeBrandQueryLimitTo {
    device = "DEVICE",
    patient = "PATIENT",
    eventType = "EVENT_TYPE"
}
export enum MaudeField {
    adverseEventBrandName = "ADVERSE_EVENT_BRAND_NAME",
    applicationNumber = "APPLICATION_NUMBER",
    brandName = "BRAND_NAME",
    companyName = "COMPANY_NAME",
    createdDate = "CREATED_DATE",
    dateReceived = "DATE_RECEIVED",
    deviceProblem = "DEVICE_PROBLEM",
    deviceProblemFdaCode = "DEVICE_PROBLEM_FDA_CODE",
    establishmentName = "ESTABLISHMENT_NAME",
    eventType = "EVENT_TYPE",
    familyBrand = "FAMILY_BRAND",
    familyCompany = "FAMILY_COMPANY",
    gmdn = "GMDN",
    gudid = "GUDID",
    manufacturer = "MANUFACTURER",
    patientProblem = "PATIENT_PROBLEM",
    patientProblemFdaCode = "PATIENT_PROBLEM_FDA_CODE",
    productCode = "PRODUCT_CODE",
    reportSourceCode = "REPORT_SOURCE_CODE",
    reportedOutcome = "REPORTED_OUTCOME",
    reportType = "REPORT_TYPE",
    reviewPanel = "REVIEW_PANEL",
    singleUseLabeled = "SINGLE_USE_LABELED",
    singleUseReprocessed = "SINGLE_USE_REPROCESSED"
}
export enum MaudeFieldName {
    applicationNumber = "APPLICATION_NUMBER",
    brandName = "BRAND_NAME",
    eventType = "EVENT_TYPE",
    manufacturer = "MANUFACTURER",
    productCode = "PRODUCT_CODE",
    receivedDate = "RECEIVED_DATE",
    relevance = "RELEVANCE",
    reportKey = "REPORT_KEY"
}
export enum MaudeHistogram {
    applicationNumber = "APPLICATION_NUMBER",
    deviceProblem = "DEVICE_PROBLEM",
    eventType = "EVENT_TYPE",
    establishmentName = "ESTABLISHMENT_NAME",
    familyBrand = "FAMILY_BRAND",
    familyCompany = "FAMILY_COMPANY",
    gmdn = "GMDN",
    manufacturer = "MANUFACTURER",
    patientProblem = "PATIENT_PROBLEM",
    productCode = "PRODUCT_CODE",
    reportSourceCode = "REPORT_SOURCE_CODE",
    reportedOutcome = "REPORTED_OUTCOME",
    reportType = "REPORT_TYPE",
    singleUseLabeled = "SINGLE_USE_LABELED",
    singleUseReprocessed = "SINGLE_USE_REPROCESSED"
}
export enum MaudeWithIn {
    aeBrandName = "AE_BRAND_NAME",
    applicationNumber = "APPLICATION_NUMBER",
    brandName = "BRAND_NAME",
    companyName = "COMPANY_NAME",
    events = "EVENTS",
    deviceProblem = "DEVICE_PROBLEM",
    narrative = "NARRATIVE",
    patientProblem = "PATIENT_PROBLEM"
}
export enum Operator {
    or = "OR",
    and = "AND"
}
export enum PageFieldName {
    gudidCount = "GUDID_COUNT",
    publishDate = "PUBLISH_DATE",
    decisionDate = "DECISION_DATE"
}
export enum Period {
    week = "WEEK",
    month = "MONTH"
}
export enum PmaField {
    applicationNumber = "APPLICATION_NUMBER",
    applicant = "APPLICANT",
    brandName = "BRAND_NAME",
    clinicalTrial = "CLINICAL_TRIAL",
    committee = "COMMITTEE",
    decisionDate = "DECISION_DATE",
    deviceNumber = "DEVICE_NUMBER",
    establishmentName = "ESTABLISHMENT_NAME",
    familyCompany = "FAMILY_COMPANY",
    postApprovalStudy = "POST_APPROVAL_STUDY",
    productCode = "PRODUCT_CODE",
    recallCount = "RECALL_COUNT",
    reviewPanel = "REVIEW_PANEL"
}
export enum PmaSupplementField {
    applicant = "APPLICANT",
    decisionDate = "DECISION_DATE",
    applicationNumber = "APPLICATION_NUMBER",
    reviewPanel = "REVIEW_PANEL",
    supplementNumber = "SUPPLEMENT_NUMBER",
    supplementType = "SUPPLEMENT_TYPE"
}
export enum PmaSupplementTableFieldName {
    applicant = "APPLICANT",
    decisionDate = "DECISION_DATE",
    number = "NUMBER",
    reviewPanel = "REVIEW_PANEL",
    productCode = "PRODUCT_CODE",
    title = "TITLE"
}
export enum PmaTableFieldName {
    applicant = "APPLICANT",
    brandName = "BRAND_NAME",
    decisionDate = "DECISION_DATE",
    number = "NUMBER",
    reviewPanel = "REVIEW_PANEL",
    reviewTime = "REVIEW_TIME",
    productCode = "PRODUCT_CODE",
    title = "TITLE"
}
export enum PmiField {
    adverseEventBrandName = "ADVERSE_EVENT_BRAND_NAME",
    applicationNumber = "APPLICATION_NUMBER",
    basilDateCreated = "BASIL_DATE_CREATED",
    brandName = "BRAND_NAME",
    companyName = "COMPANY_NAME",
    createdDate = "CREATED_DATE",
    date = "DATE",
    deviceProblem = "DEVICE_PROBLEM",
    establishmentName = "ESTABLISHMENT_NAME",
    eventType = "EVENT_TYPE",
    familyBrand = "FAMILY_BRAND",
    familyCompany = "FAMILY_COMPANY",
    fdaDeterminedCause = "FDA_DETERMINED_CAUSE",
    gmdn = "GMDN",
    manufacturer = "MANUFACTURER",
    patientProblem = "PATIENT_PROBLEM",
    productCode = "PRODUCT_CODE",
    recallBrandName = "RECALL_BRAND_NAME",
    recallClass = "RECALL_CLASS",
    recallStatus = "RECALL_STATUS",
    reportSourceCode = "REPORT_SOURCE_CODE",
    reportedOutcome = "REPORTED_OUTCOME",
    reportType = "REPORT_TYPE",
    reviewPanel = "REVIEW_PANEL",
    singleUseLabeled = "SINGLE_USE_LABELED",
    singleUseReprocessed = "SINGLE_USE_REPROCESSED"
}
export enum PmiFieldName {
    applicationNumber = "APPLICATION_NUMBER",
    brandName = "BRAND_NAME",
    eventType = "EVENT_TYPE",
    manufacturer = "MANUFACTURER",
    productCode = "PRODUCT_CODE",
    receivedDate = "RECEIVED_DATE",
    relevance = "RELEVANCE",
    reportKey = "REPORT_KEY"
}
export enum PmiLimitTo {
    adverseEvent = "ADVERSE_EVENT",
    recall = "RECALL"
}
export enum PmiWithin {
    action = "ACTION",
    aeBrandName = "AE_BRAND_NAME",
    brandName = "BRAND_NAME",
    codeInformation = "CODE_INFORMATION",
    companyName = "COMPANY_NAME",
    deviceProblem = "DEVICE_PROBLEM",
    events = "EVENTS",
    manufacturerReason = "MANUFACTURER_REASON",
    narrative = "NARRATIVE",
    patientProblem = "PATIENT_PROBLEM"
}
export enum ProductCodeField {
    date = "DATE",
    decisionDate = "DECISION_DATE",
    productCode = "PRODUCT_CODE",
    reviewPanel = "REVIEW_PANEL"
}
export enum ProductCodeTableFieldName {
    number = "NUMBER",
    reviewPanel = "REVIEW_PANEL",
    productCode = "PRODUCT_CODE"
}
export enum RecallField {
    applicationNumber = "APPLICATION_NUMBER",
    basilDateCreated = "BASIL_DATE_CREATED",
    brandName = "BRAND_NAME",
    companyName = "COMPANY_NAME",
    dateInitiated = "DATE_INITIATED",
    dateMerged = "DATE_MERGED",
    establishmentName = "ESTABLISHMENT_NAME",
    eventId = "EVENT_ID",
    familyBrand = "FAMILY_BRAND",
    familyCompany = "FAMILY_COMPANY",
    fdaDeterminedCause = "FDA_DETERMINED_CAUSE",
    gmdn = "GMDN",
    gudid = "GUDID",
    productCode = "PRODUCT_CODE",
    recallBrandName = "RECALL_BRAND_NAME",
    recallClass = "RECALL_CLASS",
    recallNumber = "RECALL_NUMBER",
    recallStatus = "RECALL_STATUS",
    recallingFirm = "RECALLING_FIRM",
    reviewPanel = "REVIEW_PANEL"
}
export enum RecallFieldName {
    relevance = "RELEVANCE"
}
export enum RecallHistogram {
    applicationNumber = "APPLICATION_NUMBER",
    familyBrand = "FAMILY_BRAND",
    familyCompany = "FAMILY_COMPANY",
    firmName = "FIRM_NAME"
}
export enum RecallWithin {
    action = "ACTION",
    brandName = "BRAND_NAME",
    codeInformation = "CODE_INFORMATION",
    companyName = "COMPANY_NAME",
    manufacturerReason = "MANUFACTURER_REASON"
}
export enum RegulationField {
    basilDateCreated = "BASIL_DATE_CREATED"
}
export enum RelatedDocCategory {
    fdaPdf = "FDA_PDF"
}
export enum RelatedDocContentType {
    applicationPdf = "application/pdf"
}
export enum ReviewPanelField {
    applicationNumber = "APPLICATION_NUMBER",
    establishmentName = "ESTABLISHMENT_NAME",
    familyBrand = "FAMILY_BRAND",
    familyCompany = "FAMILY_COMPANY",
    gmdn = "GMDN",
    gudid = "GUDID",
    productCode = "PRODUCT_CODE",
    reviewPanel = "REVIEW_PANEL"
}
export enum ReviewPanelPmiLimitTo {
    adverseEvent = "ADVERSE_EVENT",
    inspection = "INSPECTION",
    recall = "RECALL",
    warningLetter = "WARNING_LETTER"
}
export enum ReviewPanelRegulatoryLimitTo {
    deNovo = "DE_NOVO",
    fivetenk = "FIVETENK",
    hde = "HDE",
    hdeSupplement = "HDE_SUPPLEMENT",
    pma = "PMA",
    pmaSupplement = "PMA_SUPPLEMENT",
    productCode = "PRODUCT_CODE"
}
export enum SearchType {
    filter = "FILTER",
    query = "QUERY"
}
export enum SearchWithIn {
    brandName = "BRAND_NAME"
}
export enum TableFieldName {
    brandName = "BRAND_NAME",
    companyName = "COMPANY_NAME",
    devicePublishDate = "DEVICE_PUBLISH_DATE"
}
export enum UkDrugField {
    emcId = "EMC_ID"
}
export enum WarningLetterField {
    basilDateCreated = "BASIL_DATE_CREATED",
    companyName = "COMPANY_NAME",
    familyCompany = "FAMILY_COMPANY",
    issueDate = "ISSUE_DATE",
    postedDate = "POSTED_DATE"
}
export enum MonacoModelsDeNovoDecisionCode {
    deng = "DENG",
    denovogrant = "DENOVOGRANT"
}
export enum MonacoModelsFivetenkDecisionCode {
    sesu = "SESU",
    sn = "SN",
    sesp = "SESP",
    pt = "PT",
    sesd = "SESD",
    sesk = "SESK",
    sekd = "SEKD",
    si = "SI",
    st = "ST",
    se = "SE",
    sese = "SESE"
}
export enum OrderByFieldName {
    gudidCount = "GUDID_COUNT",
    publishDate = "PUBLISH_DATE",
    decisionDate = "DECISION_DATE"
}
export enum ProductCodeType {
    classification = "CLASSIFICATION",
    subsequent = "SUBSEQUENT"
}
export enum DrugBrandDatasetType {
    drugBrand = "DRUG_BRAND"
}
export enum DrugTrialDatasetType {
    drugTrial = "DRUG_TRIAL"
}
export enum EuukDrugDatasetType {
    euUkDrug = "EU_UK_DRUG"
}
export enum ExecutiveRegulatoryDatasetType {
    productCodes = "PRODUCT_CODES",
    manufacturers = "MANUFACTURERS"
}
export enum GuidanceDatasetType {
    guidance = "GUIDANCE"
}
export enum MultiDrugDatasetType {
    multiDrug = "MULTI_DRUG"
}
export enum PmiDatasetType {
    brands = "BRANDS",
    categories = "CATEGORIES",
    companies = "COMPANIES",
    reviewPanel = "REVIEW_PANEL"
}
export enum SavedItemType {
    deviceSearch = "DEVICE_SEARCH",
    deviceItem = "DEVICE_ITEM",
    regulatoryReviewPanel = "REGULATORY_REVIEW_PANEL"
}
export enum ShareStatus {
    active = "ACTIVE",
    archived = "ARCHIVED",
    deleted = "DELETED",
    unsent = "UNSENT"
}
export enum ShareType {
    recipient = "RECIPIENT",
    sender = "SENDER"
}
